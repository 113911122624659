import React, { Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { PageContext } from '@/components/layout/page/PageContext';
import intlUrl from '@/components/i18n/intlUrl';
import Translate from '@/components/i18n/Translate';
import './FourOhFour.scss';

/**
 * @function FourOhFour
 * @return {React.ReactElement}
 */
const FourOhFour = () => {
	const { edition } = useContext(PageContext);
	return (
		<Fragment>
			<Helmet>
				<title>Error Page</title>
				<style type="text/css">{`
						html,body {
							height: 100%;
						}
						div#root {
							height: auto;
							min-height: 100%;
							background: #000;
						}
						.page-footer .global-footer__line--first:first-child {
							display: none;
						}
					`}</style>
			</Helmet>
			<div className="fof columns" data-hook="four-oh-four-body">
				<div className="fof-content column is-offset-1-tablet is-5-tablet is-4-desktop">
					<h1>
						<Translate word="OOPS" />
					</h1>
					<div className="fof-text">
						<h4>
							<Translate word="PAGE_NO_EXIST" />
						</h4>
						<p>
							<Translate word="SIMPLE_FAVOR" />
						</p>
					</div>
					<a href={intlUrl(edition, '/')} className="button button--lrg button--full">
						<Translate word="TRENDING_STORIES" />
					</a>
					<a
						href={intlUrl(edition, '/news')}
						className="button button--lrg button--full is-secondary"
					>
						<Translate word="LATEST_NEWS" />
					</a>
					<a
						href={intlUrl(edition, '/shows')}
						className="button button--lrg button--full is-secondary"
					>
						<Translate word="E_SHOWS" />
					</a>
				</div>
			</div>
		</Fragment>
	);
};

FourOhFour.displayName = 'FourOhFour';

export default FourOhFour;
